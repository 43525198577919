// Version 5.9
// works fine till here 
// source code works absolutely fine till here ********************

import React, { useState, useRef, useEffect } from 'react';
import Webcam from 'react-webcam';
import { Rnd } from 'react-rnd';
import axios from 'axios';
import RecordRTC from 'recordrtc';
import { SelfieSegmentation } from '@mediapipe/selfie_segmentation';
// import { FaceMesh } from '@mediapipe/face_mesh';


import './InterviewScreen.css';
import { initializeSpeechSynthesizer, speakText} from '../utils/textToSpeech';
import { initializeSpeechRecognizer, startContinuousRecognition } from '../utils/longSpeechToText';
import { useNavigate } from 'react-router-dom';
import config from '../config';
import { v4 as uuidv4 } from 'uuid';
// import { truncatedNormal } from '@tensorflow/tfjs';

let combinedStream = null;
let mergedAudioStream = null;
let audioTracks = null;


// const faceMesh = new FaceMesh({
//     locateFile: (file) => `https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh/${file}`
// });
// faceMesh.setOptions({
//     maxNumFaces: 1,  // Limit to one face
//     refineLandmarks: true,
// });
const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);





const InterviewScreen = ({ interviewId, candidateId, interviewIdurl }) => {
    const [isSpeaking, setIsSpeaking] = useState(false);
    const [isWaiting, setIsWaiting] = useState(false);

    const [isListening, setIsListening] = useState(false);
    const [interviewStarted, setInterviewStarted] = useState(false);
    const [interviewEnded, setInterviewEnded] = useState(false);
    const webcamRef = useRef(null);
    const canvasRef = useRef(null); // Add this line
    const audioElementRef = useRef(null);
    
    const synthesizerRef = useRef(null);
    const recognizerRef = useRef(null);
    const recorderRef = useRef(null);
    const [CurrentQuestionNumber, setCurrentQuestionNumber] = useState(0);
    const [timeElapsed, setTimeElapsed] = useState(0);
    const navigate = useNavigate();
    const currentquestionstr = useRef("");
    const questionStartTm = useRef(new Date());
    const responseStartTm = useRef(new Date());
    const currentTextstr = useRef("");
    const segmentationRef = useRef(null);
    const [backgroundOption, setBackgroundOption] = useState('none');
    const [avatarbackgroundColor, setAvatarbackgroundColor] = useState('none');
    const [languageOption, setLanguageOption] = useState('none');
    const [avatarimageOption, setAvatarimageOption] = useState('none');
    // const [canvasWidth, setCanvasWidth] = useState('none');
    const [isSupported, setIsSupported] = useState(false); // Track support for getUserMedia
    const [userCompany, setUserCompany] = useState(0);

    currentTextstr.current = "When you're ready, click 'Start Interview'. The AI Interviewer will ask a series of questions and wait for your response, indicated by a flashing RED icon. Please answer each question as thoroughly as possible. After 8 seconds of silence, the interview will automatically proceed to the next question. You may end the interview at any time by selecting 'End Interview'. Feel free to select your preferred camera background before starting."




    const backgroundImageRefs = {
        office1: useRef(new Image()),
        office2: useRef(new Image()),
        beach: useRef(new Image()),
    };

 
    const backgrounds = [
        '',
        'navy',
        'slategray',
        'darkslateblue',
        'midnightblue',
        'teal',
        'olive',
        'beige',
        'lavender',
        'coral',
        'firebrick',
        'darkgreen',
        'steelblue',
        'saddlebrown',
        'darkolivegreen',
        'dimgray',
        'lightslategray',
        'indigo',
        'mediumorchid',
        'rebeccapurple',
        'slateblue'
      ];

    backgroundImageRefs.office1.current.src = 'office1.png';
    backgroundImageRefs.office2.current.src = 'office2.png';
    backgroundImageRefs.beach.current.src = 'beach.png';

            // Load the background images
            backgroundImageRefs.office1.current.onload = () => {
                //console.log('Office1 background image loaded');
            };
            backgroundImageRefs.office2.current.onload = () => {
                //console.log('Office2 background image loaded');
            };
            backgroundImageRefs.beach.current.onload = () => {
               // console.log('Beach background image loaded');
            };

    const segmentation = new SelfieSegmentation({ locateFile: (file) => `https://cdn.jsdelivr.net/npm/@mediapipe/selfie_segmentation/${file}` });
    segmentation.setOptions({
        modelSelection: 1,
    });

    // const faceMesh = new FaceMesh({
    //     locateFile: (file) => `https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh/${file}`
    // });
    // faceMesh.setOptions({
    //     maxNumFaces: 1,  // Limit to one face
    //     refineLandmarks: true,
    // });



    const handleStartInterview = async () => {
  
        setInterviewStarted(true);
        try {
                currentquestionstr.current = "Ready for your interview? Please wait..."
                const response = await axios.get(`${config.apiBaseUrl}/api/initial_data/${interviewId}/`);
                // console.log(response.data)
                setUserCompany(response.data.usercompany)
                // console.log("UserCompany", response.data.usercompany)
                setCurrentQuestionNumber(CurrentQuestionNumber + 1);
                // console.log("Acquire initial data", response.data);
              } catch (error) {
                  console.error("Error fetching initial data:", error);
              }

        //console.log('Start Interview button clicked');
    };
    



    useEffect(() => {
                        
        const loadSegmentation = async () => {
            segmentation.onResults(onResults);
            segmentationRef.current = segmentation;
            if (webcamRef.current && webcamRef.current.video.readyState === 4) {
                // const videoWidth = webcamRef.current.video.videoWidth;
                // const videoHeight = webcamRef.current.video.videoHeight;
                // const aspectRatio = videoWidth / videoHeight;
                // setCanvasWidth(aspectRatio * 360);
                // console.log("canvasWidth", canvasWidth);
                segmentVideo();
            } else {
                webcamRef.current.video.onloadeddata = () => {
                    segmentVideo();
                };
            }
        };
        loadSegmentation();


        return () => {
            if (webcamRef.current && webcamRef.current.video) {
                webcamRef.current.video.onloadeddata = null;
            }
        };

    }, [backgroundOption]);

    const segmentVideo = async () => {
        if (!webcamRef.current || !segmentationRef.current) {
            return;
        }
        const { video } = webcamRef.current;
        segmentationRef.current = segmentation;
        await segmentationRef.current.send({ image: video }); // this line gives error on changing if one video effect is running 


        requestAnimationFrame(segmentVideo);
    };

    const onResults = async (results) => {
        if (!canvasRef.current || !webcamRef.current) {
            return;
        }
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');

        if (canvas.width === 0) {
          canvas.width = results.image.width;
          canvas.height = results.image.height;
        
        }

    // Use FaceMesh to detect facial landmarks
        // const faceMeshResults = await faceMesh.send({ image: results.image });


        switch (backgroundOption) {
            case 'none':
              context.save();
              context.clearRect(0, 0, canvas.width, canvas.height);
              context.drawImage(results.image, 0, 0, canvas.width, canvas.height);
              break;

            case 'blurred':
              context.save();
              context.clearRect(0, 0, canvas.width, canvas.height);
              context.drawImage(results.segmentationMask, 0, 0, canvas.width, canvas.height);
              context.globalCompositeOperation = 'source-in';
              context.drawImage(results.image, 0, 0, canvas.width, canvas.height);
              context.globalCompositeOperation = 'destination-atop';
              context.filter = 'blur(6px)';
              context.drawImage(results.image, 0, 0, canvas.width, canvas.height);
              //context.restore();
              break;


            case 'office1':
            // Draw the background image
              context.drawImage(backgroundImageRefs.office1.current, 0, 0, canvas.width, canvas.height);
              // Draw the segmentation mask
              context.globalCompositeOperation = 'destination-out';
              context.drawImage(results.segmentationMask, 0, 0, canvas.width, canvas.height);


            // Draw the faceMesh landmarks for additional accuracy
            //   drawFaceMesh(faceMeshResults, context);

              // Draw the original image on top to keep the person clear
              context.globalCompositeOperation = 'destination-atop';
              context.drawImage(results.image, 0, 0, canvas.width, canvas.height);
              // Reset the global composite operation
              context.globalCompositeOperation = 'source-over';
              break;
            
            case 'office2':
                context.drawImage(backgroundImageRefs.office2.current, 0, 0, canvas.width, canvas.height);
                // Draw the segmentation mask
                context.globalCompositeOperation = 'destination-out';
                context.drawImage(results.segmentationMask, 0, 0, canvas.width, canvas.height);
                // Draw the original image on top to keep the person clear
                context.globalCompositeOperation = 'destination-atop';
                context.drawImage(results.image, 0, 0, canvas.width, canvas.height);
                // Reset the global composite operation
                context.globalCompositeOperation = 'source-over';
                break;

            case 'beach':
                context.drawImage(backgroundImageRefs.beach.current, 0, 0, canvas.width, canvas.height);
                // Draw the segmentation mask
                context.globalCompositeOperation = 'destination-out';
                context.drawImage(results.segmentationMask, 0, 0, canvas.width, canvas.height);
                // Draw the original image on top to keep the person clear
                context.globalCompositeOperation = 'destination-atop';
                context.drawImage(results.image, 0, 0, canvas.width, canvas.height);
                // Reset the global composite operation
                context.globalCompositeOperation = 'source-over';
                break;

            default:
                context.drawImage(results.image, 0, 0, canvas.width, canvas.height);
                break;
        }

        context.restore();
    };

    // if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
    //     setIsSupported(false);
    //     currentTextstr.current = "Your browser does not support required functionality. Please upgrade your browser"
    
    // } else
    // {
    //     setIsSupported(true);
    //     currentTextstr.current = "When you're ready, click 'Start Interview'. The AI Interviewer will ask a series of questions and wait for your response, indicated by a flashing RED icon. Please answer each question as thoroughly as possible. After 8 seconds of silence, the interview will automatically proceed to the next question. You may end the interview at any time by selecting 'End Interview'. Feel free to select your preferred camera background before starting."

    // }
    // currentTextstr.current = "Your browser does not support required functionality. Please upgrade your browser"



    // const drawFaceMesh = (faceMeshResults, context) => {
    //     if (faceMeshResults && faceMeshResults.multiFaceLandmarks) {
    //         context.beginPath();
    //         faceMeshResults.multiFaceLandmarks.forEach((landmarks) => {
    //             landmarks.forEach((landmark, index) => {
    //                 if (index === 0) {
    //                     context.moveTo(landmark.x * canvasRef.current.width, landmark.y * canvasRef.current.height);
    //                 } else {
    //                     context.lineTo(landmark.x * canvasRef.current.width, landmark.y * canvasRef.current.height);
    //                 }
    //             });
    //             context.closePath();
    //             context.clip(); // Clip based on face outline
    //         });
    //     }
    // };


    const startInterview = async () => {

        if (interviewStarted){
            setIsWaiting(true);
            console.log("languageOption", languageOption)
            synthesizerRef.current = initializeSpeechSynthesizer(languageOption);
            recognizerRef.current = initializeSpeechRecognizer();
            const questionData = await fetchQuestionInstruction(interviewId);
            currentquestionstr.current = questionData.aiuser_instruction;
            currentTextstr.current = questionData.aiuser_instruction;
            questionStartTm.current = questionData.current_time
            responseStartTm.current = questionStartTm.current
            setIsWaiting(false);

            if (questionData.action_id ===1)   // 1 means its just a text to be spoken
            {
                    await processQuestion(questionData.aiuser_instruction, questionData.action_id, handleOnComplete);
                } 
        
            if (questionData.action_id ===2)   // 2 means its a question and will listen for response
            {
                await processQuestion(questionData.aiuser_instruction, questionData.action_id, handleOnComplete);
            } 

            if (questionData.action_id ===3)   // 3 means the program will use last candidate response and get appropriate response from OpenAI
            {
                const tempresponseText = await callChatAPI(questionData.aiuser_instruction);
                await processQuestion(tempresponseText, questionData.action_id, handleOnComplete);

            } 

            if (questionData.action_id ===100) {
                setInterviewEnded(true)
                try {
                    const response = await axios.post(`${config.apiBaseUrl}/api/end_completed/${interviewId}/`);
                    navigate('/thank-you'); // Redirect to thank you page
                } catch (error) {
                    console.error("Error ending interview:", error);
                }

            }
        }
        else
        {
        }

    };


/* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        const initiateInterview = async () => {
            try {
                setIsSpeaking(false);
                setIsListening(false);
                setIsWaiting(false);



                if (!interviewStarted) {
                    const response = await axios.get(`${config.apiBaseUrl}/api/get-avatar/${interviewId}/`);
                    // Handle the response data
                    const avatarData = response.data;
                    // console.log('Avatar Data:', avatarData);
                    setAvatarimageOption(avatarData.face_image)
                    setAvatarbackgroundColor(backgrounds[avatarData.background])
                    setLanguageOption(avatarData.voice)
                }

                await startInterview();
            } catch (error) {
                console.error(error);
            }
        };
        initiateInterview();
    }, [CurrentQuestionNumber]);

    const processQuestion = async (questionText, actionidd, handleOnComplete) => {
        setIsWaiting(false);
        setIsSpeaking(true);
        // Start recording
        if (canvasRef.current && webcamRef.current) {

            // const canvasStream = canvasRef.current.captureStream(30);
            // const audioTracks = webcamRef.current.stream.getAudioTracks();
            // combinedStream = new MediaStream([...canvasStream.getTracks(), ...audioTracks]);

            // recorder = new RecordRTC(combinedStream, {
            //     type: 'video',
            //     mimeType: 'video/webm',
            //     audioBitsPerSecond: 128000, // 320 kbps for audio
            //     videoBitsPerSecond: 2000000, // 10 Mbps for video
            //     bitsPerSecond: 2128000, // Combined audio and video bitrate
            //     disableLogs: false // Disable RecordRTC logs

            // });

            // recorder.startRecording();
            // recorderRef.current = recorder;

            
            if (actionidd === 1) { // 
                await speakText(synthesizerRef.current, questionText, () => {
                    setIsSpeaking(false);

                    if (handleOnComplete) {
                        handleOnComplete("Response not expected");
                    }
                }, setTTSBlob);
            } else {
                await speakText(synthesizerRef.current, questionText, () => {
                    setIsListening(true);
                    setIsSpeaking(false);
                    startContinuousRecognition(recognizerRef.current, handleOnComplete);
                }, setTTSBlob);
            }

        }
    };

    
    // async function inspectAudioBlob(audioBlob) {
    //     // Print basic Blob properties
    //     console.log('Blob size:', audioBlob.size, 'bytes');
    //     console.log('Blob type:', audioBlob.type);
    
    //     // Create an object URL to load the Blob as an audio element
    //     const audioURL = URL.createObjectURL(audioBlob);
    
    //     // Create an AudioContext (this should be defined outside the onloadedmetadata event)
    //     const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    
    //     // Create an audio element to get basic audio metadata
    //     const audio = new Audio();
    //     audio.src = audioURL;
    
    //     // Wait until the metadata is loaded
    //     audio.onloadedmetadata = async () => {
    //         console.log('Audio duration:', audio.duration, 'seconds');
    
    //         try {
    //             // Use the Web Audio API to decode the audio and get more detailed information
    //             const arrayBuffer = await audioBlob.arrayBuffer();
    //             const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
    
    //             // Print detailed audio properties
    //             console.log('Number of channels:', audioBuffer.numberOfChannels);
    //             console.log('Sample rate:', audioBuffer.sampleRate, 'Hz');
    //             console.log('Duration (from buffer):', audioBuffer.duration, 'seconds');
    
    //             for (let i = 0; i < audioBuffer.numberOfChannels; i++) {
    //                 console.log(`Channel ${i + 1} data length:`, audioBuffer.getChannelData(i).length);
    //             }
    //         } catch (error) {
    //             console.error('Error decoding audio data:', error);
    //         }
    //     };
    
    //     // Handle error in loading the audio metadata
    //     audio.onerror = (e) => {
    //         console.error('Error loading audio metadata:', e);
    //     };
    // }
        

const setTTSBlob = async (audioBlob) => {
    try {
        // inspectAudioBlob(audioBlob);
    let recorder = null;
    const canvasStream = canvasRef.current.captureStream(30);
    audioTracks = webcamRef.current.stream.getAudioTracks();

    mergedAudioStream = await mergeAudioStreams(audioBlob, audioTracks);



    const srcAvatar = avatarimageOption;  // Path to your image file
    const imageCombinedStream = await streamImageInCornerAndMerge(srcAvatar, canvasStream)

    // combinedStream = new MediaStream([
    //     ...canvasStream.getTracks(),
    //     ...mergedAudioStream.getAudioTracks()  // Extract audio tracks from mergedAudioStream
    // ]);

    combinedStream = new MediaStream([
        ...imageCombinedStream.getTracks(),
        ...mergedAudioStream.getAudioTracks()  // Extract audio tracks from mergedAudioStream
    ]);

        recorder = new RecordRTC(combinedStream, {
            type: 'video',
            mimeType: 'video/webm',
            videoBitsPerSecond: 2500000,  // 5 Mbps for best 720p video quality
            audioBitsPerSecond: 128000,   // 192 kbps for high-quality audio
            disableLogs: true // Disable RecordRTC logs
        });


    recorder.startRecording();
    recorderRef.current = recorder;


    } catch (error) {
        console.error("Error while setting TTS Blob:", error);
    }
};


async function mergeAudioStreams(ttsAudioBlob, audioTracks) {
    // Step 1: Create an AudioContext to handle the decoding and format conversion
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();

    // Step 2: Decode the TTS audio blob into audio buffer
    const ttsArrayBuffer = await ttsAudioBlob.arrayBuffer();
    const ttsAudioBuffer = await audioContext.decodeAudioData(ttsArrayBuffer);

    // Step 3: Get the sample rate, number of channels, and duration of the TTS audio
    const ttsSampleRate = ttsAudioBuffer.sampleRate;
    const ttsNumberOfChannels = ttsAudioBuffer.numberOfChannels;

    // Step 4: Get the format of the webcam audio (audioTracks)
    const webcamAudioContext = new (window.AudioContext || window.webkitAudioContext)();
    const webcamSource = webcamAudioContext.createMediaStreamSource(new MediaStream(audioTracks));

    const webcamSampleRate = webcamAudioContext.sampleRate;
    const webcamNumberOfChannels = webcamSource.channelCount;

    // Step 5: Check compatibility
    let resampledTTSBuffer;
    if (ttsSampleRate !== webcamSampleRate || ttsNumberOfChannels !== webcamNumberOfChannels) {
        // If the formats don't match, resample the TTS audio to match the webcam audio format
        const offlineContext = new OfflineAudioContext(
            webcamNumberOfChannels, 
            ttsAudioBuffer.duration * webcamSampleRate, 
            webcamSampleRate
        );

        // Create buffer source for TTS audio and connect to offline context
        const ttsSource = offlineContext.createBufferSource();
        ttsSource.buffer = ttsAudioBuffer;
        ttsSource.connect(offlineContext.destination);
        ttsSource.start();

        // Render the resampled buffer
        resampledTTSBuffer = await offlineContext.startRendering();
    } else {
        resampledTTSBuffer = ttsAudioBuffer; // No resampling needed
    }

    // Step 6: Create a destination for merging both tracks
    const destination = audioContext.createMediaStreamDestination();

    // Step 7: Connect both webcam audio and TTS audio to the destination
    const ttsSourceNode = audioContext.createBufferSource();
    ttsSourceNode.buffer = resampledTTSBuffer;
    ttsSourceNode.connect(destination);

    const webcamSourceNode = audioContext.createMediaStreamSource(new MediaStream(audioTracks));
    webcamSourceNode.connect(destination);

    // Play the TTS audio
    ttsSourceNode.start();

    // Step 8: Return the merged audio stream
    return destination.stream; // This contains both TTS and webcam audio merged
}



async function streamImageInCornerAndMerge(imageSrc, canvasStream) {
    try {
        // Step 1: Create a canvas element and get its context
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        // Step 2: Set canvas dimensions to match the webcam video stream's dimensions
        const videoTrack = canvasStream.getVideoTracks()[0];
        const videoSettings = videoTrack.getSettings();
        canvas.width = videoSettings.width;
        canvas.height = videoSettings.height;

        // Step 3: Create a video element to play the webcam stream
        const videoElement = document.createElement('video');
        videoElement.srcObject = canvasStream;
        await videoElement.play();

        // Step 4: Load the image to overlay
        const image = new Image();
        image.src = imageSrc;

        await new Promise((resolve, reject) => {
            image.onload = resolve;
            image.onerror = reject;
        });

        // Step 5: Dynamically scale the image to fit within the canvas
        const maxImageWidth = canvas.width * 0.3; // 30% of canvas width
        const maxImageHeight = canvas.height * 0.3; // 30% of canvas height
        let imageWidth = image.width;
        let imageHeight = image.height;

        if (imageWidth > maxImageWidth) {
            const scalingFactor = maxImageWidth / imageWidth;
            imageWidth = maxImageWidth;
            imageHeight *= scalingFactor;
        }

        if (imageHeight > maxImageHeight) {
            const scalingFactor = maxImageHeight / imageHeight;
            imageHeight = maxImageHeight;
            imageWidth *= scalingFactor;
        }

        // Step 6: Position the image in the corner
        const imageX = 20; // 20px from the left
        const imageY = 20; // 20px from the top

        // Step 7: Animation loop to draw the video and the image onto the canvas
        const updateCanvas = () => {
            ctx.clearRect(0, 0, canvas.width, canvas.height);

            // Draw the webcam video onto the canvas
            ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

            // Draw the scaled image onto the canvas
            ctx.drawImage(image, imageX, imageY, imageWidth, imageHeight);

            // Continue the animation loop
            requestAnimationFrame(updateCanvas);
        };

        updateCanvas();

        // Step 8: Capture the canvas stream (which now contains the webcam video + image)
        const imageStream = canvas.captureStream(30); // Capture at 30fps

        // Step 9: Return the combined video stream
        return imageStream;

    } catch (error) {
        console.error('Error while combining image and video stream:', error);
        throw error;
    }
}



    const handleOnComplete = async (fullConversationText) => {
        setIsListening(false);
        setIsSpeaking(false);
        setIsWaiting(true);
        // console.log("Usercompany", userCompany);
        // Stop recording
        if (recorderRef.current) {

            if (recorderRef.current.getState() === 'recording') {
                recorderRef.current.stopRecording(async () => {
                    const blob = recorderRef.current.getBlob();

                    const uniqueId = uuidv4();  // Generate a unique identifier
                    const filename = `videos/question-${interviewId}-${candidateId}-${CurrentQuestionNumber}-${uniqueId}.mp4`;
                    // const filename = `question-${interviewId}-${candidateId}-${CurrentQuestionNumber}-${uniqueId}.webm`;
                    const azurefilename = `videos/question-${interviewId}-${candidateId}-${CurrentQuestionNumber}-${uniqueId}.webm`;
     
                    // Save the initial response data and get the responsedataid
                    const initialResponseData = {
                        "questionnumber": CurrentQuestionNumber,
                        "candidate": candidateId,
                        "interview": interviewId,
                        "questiontext": currentquestionstr.current,
                        "response_text": fullConversationText,
                        "response_time": responseStartTm.current,
                        "questionstarttime": questionStartTm.current,
                        "responseendtime": responseStartTm.current, // the variable is just passed for placeholder. actual responded time is filled at server side
                        "usercompany":userCompany
                    };
    
                    let responsedataid;
                    try {
                        const response = await axios.post(`${config.apiBaseUrl}/api/record_response/`, initialResponseData);
                        responsedataid = response.data.response_id;
                        //  console.log("Initial response saved successfully with ID:", responsedataid);
                    } catch (error) {
                        console.error("Error saving initial response:", error);
                        return;
                    }

                    const isCloudflare = true; // Set this flag as needed (True for Cloudflare, False for Azure)
                // Merge the TTS audio stream with the recorded video blob before uploading
                    if (isCloudflare) {
                        uploadVideoToCloudflare(blob, responsedataid, filename)
                            .then(cloudflareUrl => {
                                console.log("Upload completed");
                            })
                            .catch((error) => {
                                console.error("Error uploading video", error);
                            });
                    } else {
                        // Upload to Azure Blob Storage
                        uploadVideoToAzure(blob, azurefilename, responsedataid)
                            .then(azureUrl => {
                                console.log("Upload completed and Azure URL obtained:");
                            })
                            .catch((error) => {
                                console.error("Error uploading video to Azure:", error);
                            });
                    }
                 
                    // Start next question immediately without waiting for the upload to complete
                    setIsWaiting(false);
                    setCurrentQuestionNumber(CurrentQuestionNumber + 1);
                });
            }
        }
    };



    function downloadBlob(blob, filename) {
        // Create a URL for the blob
        const url = URL.createObjectURL(blob);
        
        // Create an anchor element
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;  // Set the file name
        
        // Append the anchor to the body
        document.body.appendChild(a);
        
        // Programmatically click the anchor to trigger the download
        a.click();
        
        // Clean up by removing the anchor and revoking the object URL
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    }



// this function gets a safe upload url from backend and then uploads the video to cloudflare    
async function uploadVideoToCloudflare(blob, responsedataid, filename) {
    try {
            // Step 1: Request a short-lived, time-limited upload URL from the backend

        const response = await axios.post(`${config.apiBaseUrl}/api/get-cloudflare-upload-url/`, {
            interviewIdurl: interviewIdurl
        });

    
            // Step 2: Use the provided URL to upload the video file directly to Cloudflare
        const uploadURL = response.data.upload_url;
        const videoid = response.data.video_id;

        const formData = new FormData();

        formData.append("file", blob, filename);  // Pass the filename as the third argument
    
        const uploadResponse = await axios.post(uploadURL, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        });     
        
        const updateResponseData = {
            "responsedataid": responsedataid,
            "video_url": videoid,
            "cloudflare" : 1,
            "cloudflare_filename" : filename
        };
        await axios.post(`${config.apiBaseUrl}/api/save_video_filename/`, updateResponseData);

        // downloadBlob(blob, filename);
        return videoid;
    
    } catch (error) {
        console.error("Error uploading video:", error);
        throw error;
    }
}
    


    function uploadVideoToAzure(blob, filename, responsedataid) {
        return axios.get(`${config.apiBaseUrl}/api/get_sas_token/`, { params: { blob_name: filename } })
        .then(response => {
                const sasToken = response.data.sas_token;
                const azureUrl = `https://aiinterviewstorage.blob.core.windows.net/aiblobstorage/${filename}?${sasToken}`;
                //console.log("sasToken *******", sasToken)

                const config1 = {
                    headers: {
                        'x-ms-blob-type': 'BlockBlob',
                        // 'Content-Type': blob.type,
                        // 'Content-Type':  "video/mp4",
                        'Content-Type':  "video/webm",

                    }
                };
    
                return axios.put(azureUrl, blob, config1)
                    .then(() => {
                        // Save the URL in the database after the upload completes
                        //console.log("azureUrl *******", azureUrl)

                        const updateResponseData = {
                            "responsedataid": responsedataid,
                            "video_url": azureUrl,
                            "cloudflare" : 0,
                        };
                        return axios.post(`${config.apiBaseUrl}/api/save_video_filename/`, updateResponseData)
                            .then(() => azureUrl)
                            .catch(error => {
                                console.error("Error updating video URL:", error);
                                throw error;
                            });
                    })
                    .catch(error => {
                        console.error("Error during file upload:", error);
                        throw error;
                    });
            })
            .catch(error => {
                console.error("Error getting SAS token:", error);
                throw error;
            });
    }
    




// this function inserts parameters into instructions // DO NOT DELETE. WILL NEED in future
// const formatTemplate = (template, values, wrapper = '') => {
//     return template.replace(/\${(.*?)}/g, (match, p1) => {
//         return values[p1] ? `${values[p1]}` : match;
//     });
// };


    const formatTime = (seconds) => {
        const mins = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return 'Time Eapsed: ' && `${mins}:${secs < 10 ? '0' : ''}${secs}`;
    };


    const handleEndInterview = async () => {
        const confirmEnd = window.confirm('Do you want to end the interview?');
        if (confirmEnd) {

            try {
                const response = await axios.post(`${config.apiBaseUrl}/api/end_interview/${interviewId}/`);
                // console.log(response.data.status);
                navigate('/thank-you'); // Redirect to thank you page
            } catch (error) {
                console.error("Error ending interview:", error);
            }
        }
    };


    const callChatAPI = async (userInput) => {

        // console.log(userInput);
        

        const responseData = {
            instruction: userInput   // instruction is the variable chat api will look for. if instruction variable is not present chatapi will give respone 500
        };


        try {
            const response = await axios.post(`${config.apiBaseUrl}/api/chat/`, responseData);
    
            // console.log('Chat API response:', response.data);
            return response.data.question;
        } catch (error) {
            console.error('Error calling chat API:', error);
            return null;
        }
    };

    const fetchQuestionInstruction = async (interviewId) => {
        try {

            const response = await axios.get(`${config.apiBaseUrl}/api/question_instruction/${interviewId}/`);
            return response.data;
        } catch (error) {
            console.error('Error fetching question instruction:', error);
            return null;
        }
    };
    


    // useEffect(() => {
    //     const getMedia = async () => {
    //         try {
    //             console.log("Requesting media.");

    //             // Request video and audio streams
    //             const stream = await navigator.mediaDevices.getUserMedia({
    //                 video: true,
    //                 audio: true,
    //             });

    //             if (isSafari) {
    //                 console.log("Safari detected. Removing audio track.");

    //                 // Remove the audio track to prevent playback
    //                 stream.getAudioTracks().forEach((track) => {
    //                     stream.removeTrack(track);
    //                     console.log("Audio track removed.");
    //                 });
    //             }

    //             // Attach the video stream to the <video> element
    //             if (webcamRef.current) {
    //                 webcamRef.current.srcObject = stream;
    //             }
    //         } catch (err) {
    //             console.error("Error accessing media devices.", err);
    //         }
    //     };

    //     getMedia();
    // }, []); // Empty dependency array ensures this runs once after component mounts




 
    useEffect(() => {

        let interval;
        if (interviewStarted && !interviewEnded) {
            interval = setInterval(() => {
                setTimeElapsed(prevTime => {
                    if (prevTime + 1 >= 5400) { // 5400 seconds = 90 minutes
                        // Action to take when 90 minutes have elapsed
                        console.log('90 minutes have elapsed');
                        navigate('/thank-you'); // Redirect to thank you page
                    }
                    return prevTime + 1;
                });
            }, 1000);
        }
        return () => clearInterval(interval);
    }, [interviewStarted, interviewEnded]);




    return (
        <div className="interview-screen" style={{ backgroundColor: avatarbackgroundColor }}>
            <img src={avatarimageOption} alt="Avatar" className="avatar" />
            <Rnd
            default={{
                x: 1,
                y: 1,
                width: 320,
                height: 180,
            }}
            bounds="parent"
        >
            <div className="webcam-container">
            </div>
            <Webcam
                audio={true} // Enable audio input for recording
                muted={true} // Mute for non-Safari browsers
                ref={webcamRef}
                width="320"
                height="180"
                videoConstraints={{
                    width: 640, // Recording size
                    height: 360, // Recording size
                }}
                className="webcam"
                style={{ position: 'absolute', top: 1, left: 1 }}
                onUserMedia={() => console.log('Webcam loaded')}
                onUserMediaError={(err) => console.error('Webcam error:', err)}
            />
            <canvas
                ref={canvasRef}
                width="640" // Match the canvas size to the recording size
                height="360"
                style={{
                    position: 'absolute',
                    top: 1,
                    left: 1,
                    width: '320px',
                    height: '180px', // Display size
                }}
            />
        </Rnd>


            <button onClick={handleStartInterview} className="start-interview-button" disabled={interviewStarted}>Start Interview</button>
            <div className="question-container">
                {interviewStarted ? (
                    <p>{currentquestionstr.current}</p>
                ) : (
                    <p>{currentTextstr.current}</p>
                )}
            </div>
            {isSpeaking && (
                <div className="speaking-indicator">
                    <img src="/speaking-icon.png" alt="Speaking" className="blinking-icon" />
                </div>
            )}
            {isListening && (
                <div className="listening-indicator">
                    <img src="/listening-icon.png" alt="Listening" className="blinking-icon" />
                </div>
            )}
            {isWaiting && (
                <div className="waiting-indicator">
                    <img src="/waiting-icon.png" alt="Waiting" className="blinking-icon" />
                </div>
            )}
            {!interviewEnded && (
                <button
                    onClick={handleEndInterview}
                    className="end-interview-button"
                    disabled={!interviewStarted}  // Disable if interview hasn't started
                >
                    End Interview
                </button>
            )}
            <select
                id="background-select"
                value={backgroundOption}
                className="background-select" disabled={interviewStarted}
                onChange={(e) => setBackgroundOption(e.target.value)}
            >
                <option value="none">No Camera Background</option>
                {!isSafari && <option value="blurred">Blurred</option>}
                <option value="office1">Office 1</option>
                <option value="office2">Office 2</option>
                <option value="beach">Beach</option>
            </select>

            <div style={{
                position: 'absolute',
                bottom: '275px',
                left: '30px',
                fontSize: '16px',
                color: 'white',
            }}>
                By clicking 'Start Interview' button, you agree to our
                <a href="https://www.bitsoft.com/privacy-policy-and-candidate-agreement/" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'underline', marginLeft: '5px' }}>
                    Terms and Conditions
                </a>
            </div>

            <div style={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                fontSize: '18px',
                color: 'white',
                backgroundColor: 'rgba(0, 0, 0, 0.5)', // optional: adds a semi-transparent background for better readability
                padding: '5px', // optional: adds padding around the text
                borderRadius: '5px' // optional: adds rounded corners
            }}>
                Time elapsed: {formatTime(timeElapsed)}
            </div>
            <div style={{
                position: 'absolute',
                bottom: '5px',
                right: '10px',
                fontSize: '12px',
                color: 'white',
                padding: '5px',
            }}>
                <a href="http://www.bitsoft.com" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none' }}>
                    Bitsoft International, Inc © 2024
                </a>
            </div>
        </div>
    );
};

export default InterviewScreen;



// // Version 5.9
// // works fine till here 
// // source code works absolutely fine till here ********************

// import React, { useState, useRef, useEffect } from 'react';
// import Webcam from 'react-webcam';
// import { Rnd } from 'react-rnd';
// import axios from 'axios';
// import RecordRTC from 'recordrtc';
// import { SelfieSegmentation } from '@mediapipe/selfie_segmentation';
// // import { FaceMesh } from '@mediapipe/face_mesh';


// import './InterviewScreen.css';
// import { initializeSpeechSynthesizer, speakText} from '../utils/textToSpeech';
// import { initializeSpeechRecognizer, startContinuousRecognition } from '../utils/longSpeechToText';
// import { useNavigate } from 'react-router-dom';
// import config from '../config';
// import { v4 as uuidv4 } from 'uuid';
// // import { truncatedNormal } from '@tensorflow/tfjs';

// let combinedStream = null;
// let mergedAudioStream = null;
// let audioTracks = null;


// // const faceMesh = new FaceMesh({
// //     locateFile: (file) => `https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh/${file}`
// // });
// // faceMesh.setOptions({
// //     maxNumFaces: 1,  // Limit to one face
// //     refineLandmarks: true,
// // });
// const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);





// const InterviewScreen = ({ interviewId, candidateId, interviewIdurl }) => {
//     const [isSpeaking, setIsSpeaking] = useState(false);
//     const [isWaiting, setIsWaiting] = useState(false);

//     const [isListening, setIsListening] = useState(false);
//     const [interviewStarted, setInterviewStarted] = useState(false);
//     const [interviewEnded, setInterviewEnded] = useState(false);
//     const webcamRef = useRef(null);
//     const canvasRef = useRef(null); // Add this line
    
//     const synthesizerRef = useRef(null);
//     const recognizerRef = useRef(null);
//     const recorderRef = useRef(null);
//     const [CurrentQuestionNumber, setCurrentQuestionNumber] = useState(0);
//     const [timeElapsed, setTimeElapsed] = useState(0);
//     const navigate = useNavigate();
//     const currentquestionstr = useRef("");
//     const questionStartTm = useRef(new Date());
//     const responseStartTm = useRef(new Date());
//     const currentTextstr = useRef("");
//     const segmentationRef = useRef(null);
//     const [backgroundOption, setBackgroundOption] = useState('none');
//     const [avatarbackgroundColor, setAvatarbackgroundColor] = useState('none');
//     const [languageOption, setLanguageOption] = useState('none');
//     const [avatarimageOption, setAvatarimageOption] = useState('none');
//     // const [canvasWidth, setCanvasWidth] = useState('none');
//     const [isSupported, setIsSupported] = useState(false); // Track support for getUserMedia
//     const [userCompany, setUserCompany] = useState(0);

//     currentTextstr.current = "When you're ready, click 'Start Interview'. The AI Interviewer will ask a series of questions and wait for your response, indicated by a flashing RED icon. Please answer each question as thoroughly as possible. After 8 seconds of silence, the interview will automatically proceed to the next question. You may end the interview at any time by selecting 'End Interview'. Feel free to select your preferred camera background before starting."


//     const backgroundImageRefs = {
//         office1: useRef(new Image()),
//         office2: useRef(new Image()),
//         beach: useRef(new Image()),
//     };

 
//     const backgrounds = [
//         '',
//         'navy',
//         'slategray',
//         'darkslateblue',
//         'midnightblue',
//         'teal',
//         'olive',
//         'beige',
//         'lavender',
//         'coral',
//         'firebrick',
//         'darkgreen',
//         'steelblue',
//         'saddlebrown',
//         'darkolivegreen',
//         'dimgray',
//         'lightslategray',
//         'indigo',
//         'mediumorchid',
//         'rebeccapurple',
//         'slateblue'
//       ];

//     backgroundImageRefs.office1.current.src = 'office1.png';
//     backgroundImageRefs.office2.current.src = 'office2.png';
//     backgroundImageRefs.beach.current.src = 'beach.png';

//             // Load the background images
//             backgroundImageRefs.office1.current.onload = () => {
//                 //console.log('Office1 background image loaded');
//             };
//             backgroundImageRefs.office2.current.onload = () => {
//                 //console.log('Office2 background image loaded');
//             };
//             backgroundImageRefs.beach.current.onload = () => {
//                // console.log('Beach background image loaded');
//             };

//     const segmentation = new SelfieSegmentation({ locateFile: (file) => `https://cdn.jsdelivr.net/npm/@mediapipe/selfie_segmentation/${file}` });
//     segmentation.setOptions({
//         modelSelection: 1,
//     });

//     // const faceMesh = new FaceMesh({
//     //     locateFile: (file) => `https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh/${file}`
//     // });
//     // faceMesh.setOptions({
//     //     maxNumFaces: 1,  // Limit to one face
//     //     refineLandmarks: true,
//     // });



//     const handleStartInterview = async () => {
  
//         setInterviewStarted(true);
//         try {
//                 currentquestionstr.current = "Ready for your interview? Please wait..."
//                 const response = await axios.get(`${config.apiBaseUrl}/api/initial_data/${interviewId}/`);
//                 // console.log(response.data)
//                 setUserCompany(response.data.usercompany)
//                 // console.log("UserCompany", response.data.usercompany)
//                 setCurrentQuestionNumber(CurrentQuestionNumber + 1);
//                 // console.log("Acquire initial data", response.data);
//               } catch (error) {
//                   console.error("Error fetching initial data:", error);
//               }

//         //console.log('Start Interview button clicked');
//     };
    



//     useEffect(() => {
                        
//         const loadSegmentation = async () => {
//             segmentation.onResults(onResults);
//             segmentationRef.current = segmentation;
//             if (webcamRef.current && webcamRef.current.video.readyState === 4) {
//                 // const videoWidth = webcamRef.current.video.videoWidth;
//                 // const videoHeight = webcamRef.current.video.videoHeight;
//                 // const aspectRatio = videoWidth / videoHeight;
//                 // setCanvasWidth(aspectRatio * 360);
//                 // console.log("canvasWidth", canvasWidth);
//                 segmentVideo();
//             } else {
//                 webcamRef.current.video.onloadeddata = () => {
//                     segmentVideo();
//                 };
//             }
//         };
//         loadSegmentation();


//         return () => {
//             if (webcamRef.current && webcamRef.current.video) {
//                 webcamRef.current.video.onloadeddata = null;
//             }
//         };

//     }, [backgroundOption]);

//     const segmentVideo = async () => {
//         if (!webcamRef.current || !segmentationRef.current) {
//             return;
//         }
//         const { video } = webcamRef.current;
//         segmentationRef.current = segmentation;
//         await segmentationRef.current.send({ image: video }); // this line gives error on changing if one video effect is running 


//         requestAnimationFrame(segmentVideo);
//     };

//     const onResults = async (results) => {
//         if (!canvasRef.current || !webcamRef.current) {
//             return;
//         }
//         const canvas = canvasRef.current;
//         const context = canvas.getContext('2d');

//         if (canvas.width === 0) {
//           canvas.width = results.image.width;
//           canvas.height = results.image.height;
        
//         }

//     // Use FaceMesh to detect facial landmarks
//         // const faceMeshResults = await faceMesh.send({ image: results.image });


//         switch (backgroundOption) {
//             case 'none':
//               context.save();
//               context.clearRect(0, 0, canvas.width, canvas.height);
//               context.drawImage(results.image, 0, 0, canvas.width, canvas.height);
//               break;

//             case 'blurred':
//               context.save();
//               context.clearRect(0, 0, canvas.width, canvas.height);
//               context.drawImage(results.segmentationMask, 0, 0, canvas.width, canvas.height);
//               context.globalCompositeOperation = 'source-in';
//               context.drawImage(results.image, 0, 0, canvas.width, canvas.height);
//               context.globalCompositeOperation = 'destination-atop';
//               context.filter = 'blur(6px)';
//               context.drawImage(results.image, 0, 0, canvas.width, canvas.height);
//               //context.restore();
//               break;

//             case 'office1':
//             // Draw the background image
//               context.drawImage(backgroundImageRefs.office1.current, 0, 0, canvas.width, canvas.height);
//               // Draw the segmentation mask
//               context.globalCompositeOperation = 'destination-out';
//               context.drawImage(results.segmentationMask, 0, 0, canvas.width, canvas.height);


//             // Draw the faceMesh landmarks for additional accuracy
//             //   drawFaceMesh(faceMeshResults, context);

//               // Draw the original image on top to keep the person clear
//               context.globalCompositeOperation = 'destination-atop';
//               context.drawImage(results.image, 0, 0, canvas.width, canvas.height);
//               // Reset the global composite operation
//               context.globalCompositeOperation = 'source-over';
//               break;
            
//             case 'office2':
//                 context.drawImage(backgroundImageRefs.office2.current, 0, 0, canvas.width, canvas.height);
//                 // Draw the segmentation mask
//                 context.globalCompositeOperation = 'destination-out';
//                 context.drawImage(results.segmentationMask, 0, 0, canvas.width, canvas.height);
//                 // Draw the original image on top to keep the person clear
//                 context.globalCompositeOperation = 'destination-atop';
//                 context.drawImage(results.image, 0, 0, canvas.width, canvas.height);
//                 // Reset the global composite operation
//                 context.globalCompositeOperation = 'source-over';
//                 break;

//             case 'beach':
//                 context.drawImage(backgroundImageRefs.beach.current, 0, 0, canvas.width, canvas.height);
//                 // Draw the segmentation mask
//                 context.globalCompositeOperation = 'destination-out';
//                 context.drawImage(results.segmentationMask, 0, 0, canvas.width, canvas.height);
//                 // Draw the original image on top to keep the person clear
//                 context.globalCompositeOperation = 'destination-atop';
//                 context.drawImage(results.image, 0, 0, canvas.width, canvas.height);
//                 // Reset the global composite operation
//                 context.globalCompositeOperation = 'source-over';
//                 break;

//             default:
//                 context.drawImage(results.image, 0, 0, canvas.width, canvas.height);
//                 break;
//         }

//         context.restore();
//     };

//     // if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
//     //     setIsSupported(false);
//     //     currentTextstr.current = "Your browser does not support required functionality. Please upgrade your browser"
    
//     // } else
//     // {
//     //     setIsSupported(true);
//     //     currentTextstr.current = "When you're ready, click 'Start Interview'. The AI Interviewer will ask a series of questions and wait for your response, indicated by a flashing RED icon. Please answer each question as thoroughly as possible. After 8 seconds of silence, the interview will automatically proceed to the next question. You may end the interview at any time by selecting 'End Interview'. Feel free to select your preferred camera background before starting."

//     // }
//     // currentTextstr.current = "Your browser does not support required functionality. Please upgrade your browser"



//     // const drawFaceMesh = (faceMeshResults, context) => {
//     //     if (faceMeshResults && faceMeshResults.multiFaceLandmarks) {
//     //         context.beginPath();
//     //         faceMeshResults.multiFaceLandmarks.forEach((landmarks) => {
//     //             landmarks.forEach((landmark, index) => {
//     //                 if (index === 0) {
//     //                     context.moveTo(landmark.x * canvasRef.current.width, landmark.y * canvasRef.current.height);
//     //                 } else {
//     //                     context.lineTo(landmark.x * canvasRef.current.width, landmark.y * canvasRef.current.height);
//     //                 }
//     //             });
//     //             context.closePath();
//     //             context.clip(); // Clip based on face outline
//     //         });
//     //     }
//     // };


//     const startInterview = async () => {

//         if (interviewStarted){
//             setIsWaiting(true);
//             console.log("languageOption", languageOption)
//             synthesizerRef.current = initializeSpeechSynthesizer(languageOption);
//             recognizerRef.current = initializeSpeechRecognizer();
//             const questionData = await fetchQuestionInstruction(interviewId);
//             currentquestionstr.current = questionData.aiuser_instruction;
//             currentTextstr.current = questionData.aiuser_instruction;
//             questionStartTm.current = questionData.current_time
//             responseStartTm.current = questionStartTm.current
//             setIsWaiting(false);

//             if (questionData.action_id ===1)   // 1 means its just a text to be spoken
//             {
//                     await processQuestion(questionData.aiuser_instruction, questionData.action_id, handleOnComplete);
//                 } 
        
//             if (questionData.action_id ===2)   // 2 means its a question and will listen for response
//             {
//                 await processQuestion(questionData.aiuser_instruction, questionData.action_id, handleOnComplete);
//             } 

//             if (questionData.action_id ===3)   // 3 means the program will use last candidate response and get appropriate response from OpenAI
//             {
//                 const tempresponseText = await callChatAPI(questionData.aiuser_instruction);
//                 await processQuestion(tempresponseText, questionData.action_id, handleOnComplete);

//             } 

//             if (questionData.action_id ===100) {
//                 setInterviewEnded(true)
//                 try {
//                     const response = await axios.post(`${config.apiBaseUrl}/api/end_completed/${interviewId}/`);
//                     navigate('/thank-you'); // Redirect to thank you page
//                 } catch (error) {
//                     console.error("Error ending interview:", error);
//                 }

//             }
//         }
//         else
//         {
//         }

//     };


// /* eslint-disable react-hooks/exhaustive-deps */
//     useEffect(() => {
//         const initiateInterview = async () => {
//             try {
//                 setIsSpeaking(false);
//                 setIsListening(false);
//                 setIsWaiting(false);



//                 if (!interviewStarted) {
//                     const response = await axios.get(`${config.apiBaseUrl}/api/get-avatar/${interviewId}/`);
//                     // Handle the response data
//                     const avatarData = response.data;
//                     // console.log('Avatar Data:', avatarData);
//                     setAvatarimageOption(avatarData.face_image)
//                     setAvatarbackgroundColor(backgrounds[avatarData.background])
//                     setLanguageOption(avatarData.voice)
//                 }

//                 await startInterview();
//             } catch (error) {
//                 console.error(error);
//             }
//         };
//         initiateInterview();
//     }, [CurrentQuestionNumber]);

//     const processQuestion = async (questionText, actionidd, handleOnComplete) => {
//         setIsWaiting(false);
//         setIsSpeaking(true);
//         // Start recording
//         if (canvasRef.current && webcamRef.current) {

//             // const canvasStream = canvasRef.current.captureStream(30);
//             // const audioTracks = webcamRef.current.stream.getAudioTracks();
//             // combinedStream = new MediaStream([...canvasStream.getTracks(), ...audioTracks]);

//             // recorder = new RecordRTC(combinedStream, {
//             //     type: 'video',
//             //     mimeType: 'video/webm',
//             //     audioBitsPerSecond: 128000, // 320 kbps for audio
//             //     videoBitsPerSecond: 2000000, // 10 Mbps for video
//             //     bitsPerSecond: 2128000, // Combined audio and video bitrate
//             //     disableLogs: false // Disable RecordRTC logs

//             // });

//             // recorder.startRecording();
//             // recorderRef.current = recorder;

            
//             if (actionidd === 1) { // 
//                 await speakText(synthesizerRef.current, questionText, () => {
//                     setIsSpeaking(false);

//                     if (handleOnComplete) {
//                         handleOnComplete("Response not expected");
//                     }
//                 }, setTTSBlob);
//             } else {
//                 await speakText(synthesizerRef.current, questionText, () => {
//                     setIsListening(true);
//                     setIsSpeaking(false);
//                     startContinuousRecognition(recognizerRef.current, handleOnComplete);
//                 }, setTTSBlob);
//             }

//         }
//     };

    
//     // async function inspectAudioBlob(audioBlob) {
//     //     // Print basic Blob properties
//     //     console.log('Blob size:', audioBlob.size, 'bytes');
//     //     console.log('Blob type:', audioBlob.type);
    
//     //     // Create an object URL to load the Blob as an audio element
//     //     const audioURL = URL.createObjectURL(audioBlob);
    
//     //     // Create an AudioContext (this should be defined outside the onloadedmetadata event)
//     //     const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    
//     //     // Create an audio element to get basic audio metadata
//     //     const audio = new Audio();
//     //     audio.src = audioURL;
    
//     //     // Wait until the metadata is loaded
//     //     audio.onloadedmetadata = async () => {
//     //         console.log('Audio duration:', audio.duration, 'seconds');
    
//     //         try {
//     //             // Use the Web Audio API to decode the audio and get more detailed information
//     //             const arrayBuffer = await audioBlob.arrayBuffer();
//     //             const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
    
//     //             // Print detailed audio properties
//     //             console.log('Number of channels:', audioBuffer.numberOfChannels);
//     //             console.log('Sample rate:', audioBuffer.sampleRate, 'Hz');
//     //             console.log('Duration (from buffer):', audioBuffer.duration, 'seconds');
    
//     //             for (let i = 0; i < audioBuffer.numberOfChannels; i++) {
//     //                 console.log(`Channel ${i + 1} data length:`, audioBuffer.getChannelData(i).length);
//     //             }
//     //         } catch (error) {
//     //             console.error('Error decoding audio data:', error);
//     //         }
//     //     };
    
//     //     // Handle error in loading the audio metadata
//     //     audio.onerror = (e) => {
//     //         console.error('Error loading audio metadata:', e);
//     //     };
//     // }
        

// const setTTSBlob = async (audioBlob) => {
//     try {
//         // inspectAudioBlob(audioBlob);
//     let recorder = null;
//     const canvasStream = canvasRef.current.captureStream(30);
//     audioTracks = webcamRef.current.stream.getAudioTracks();

//     mergedAudioStream = await mergeAudioStreams(audioBlob, audioTracks);



//     const srcAvatar = avatarimageOption;  // Path to your image file
//     const imageCombinedStream = await streamImageInCornerAndMerge(srcAvatar, canvasStream)

//     // combinedStream = new MediaStream([
//     //     ...canvasStream.getTracks(),
//     //     ...mergedAudioStream.getAudioTracks()  // Extract audio tracks from mergedAudioStream
//     // ]);

//     combinedStream = new MediaStream([
//         ...imageCombinedStream.getTracks(),
//         ...mergedAudioStream.getAudioTracks()  // Extract audio tracks from mergedAudioStream
//     ]);

//         recorder = new RecordRTC(combinedStream, {
//             type: 'video',
//             mimeType: 'video/webm',
//             videoBitsPerSecond: 2500000,  // 5 Mbps for best 720p video quality
//             audioBitsPerSecond: 128000,   // 192 kbps for high-quality audio
//             disableLogs: true // Disable RecordRTC logs
//         });


//     recorder.startRecording();
//     recorderRef.current = recorder;


//     } catch (error) {
//         console.error("Error while setting TTS Blob:", error);
//     }
// };


// async function mergeAudioStreams(ttsAudioBlob, audioTracks) {
//     // Step 1: Create an AudioContext to handle the decoding and format conversion
//     const audioContext = new (window.AudioContext || window.webkitAudioContext)();

//     // Step 2: Decode the TTS audio blob into audio buffer
//     const ttsArrayBuffer = await ttsAudioBlob.arrayBuffer();
//     const ttsAudioBuffer = await audioContext.decodeAudioData(ttsArrayBuffer);

//     // Step 3: Get the sample rate, number of channels, and duration of the TTS audio
//     const ttsSampleRate = ttsAudioBuffer.sampleRate;
//     const ttsNumberOfChannels = ttsAudioBuffer.numberOfChannels;

//     // Step 4: Get the format of the webcam audio (audioTracks)
//     const webcamAudioContext = new (window.AudioContext || window.webkitAudioContext)();
//     const webcamSource = webcamAudioContext.createMediaStreamSource(new MediaStream(audioTracks));

//     const webcamSampleRate = webcamAudioContext.sampleRate;
//     const webcamNumberOfChannels = webcamSource.channelCount;

//     // Step 5: Check compatibility
//     let resampledTTSBuffer;
//     if (ttsSampleRate !== webcamSampleRate || ttsNumberOfChannels !== webcamNumberOfChannels) {
//         // If the formats don't match, resample the TTS audio to match the webcam audio format
//         const offlineContext = new OfflineAudioContext(
//             webcamNumberOfChannels, 
//             ttsAudioBuffer.duration * webcamSampleRate, 
//             webcamSampleRate
//         );

//         // Create buffer source for TTS audio and connect to offline context
//         const ttsSource = offlineContext.createBufferSource();
//         ttsSource.buffer = ttsAudioBuffer;
//         ttsSource.connect(offlineContext.destination);
//         ttsSource.start();

//         // Render the resampled buffer
//         resampledTTSBuffer = await offlineContext.startRendering();
//     } else {
//         resampledTTSBuffer = ttsAudioBuffer; // No resampling needed
//     }

//     // Step 6: Create a destination for merging both tracks
//     const destination = audioContext.createMediaStreamDestination();

//     // Step 7: Connect both webcam audio and TTS audio to the destination
//     const ttsSourceNode = audioContext.createBufferSource();
//     ttsSourceNode.buffer = resampledTTSBuffer;
//     ttsSourceNode.connect(destination);

//     const webcamSourceNode = audioContext.createMediaStreamSource(new MediaStream(audioTracks));
//     webcamSourceNode.connect(destination);

//     // Play the TTS audio
//     ttsSourceNode.start();

//     // Step 8: Return the merged audio stream
//     return destination.stream; // This contains both TTS and webcam audio merged
// }



// async function streamImageInCornerAndMerge(imageSrc, canvasStream) {
//     try {
//         // Step 1: Create a canvas element and get its context
//         const canvas = document.createElement('canvas');
//         const ctx = canvas.getContext('2d');

//         // Step 2: Set canvas dimensions to match the webcam video stream's dimensions
//         const videoTrack = canvasStream.getVideoTracks()[0];  // Assume webcam video track
//         const videoSettings = videoTrack.getSettings();
//         canvas.width = videoSettings.width;
//         canvas.height = videoSettings.height;

//         // Step 3: Create a video element to play the webcam stream
//         const videoElement = document.createElement('video');
//         videoElement.srcObject = canvasStream;
//         await videoElement.play();  // Play the video stream

//         // Step 4: Load the image to be positioned in the upper-left corner
//         const image = new Image();
//         image.src = imageSrc;

//         await new Promise((resolve, reject) => {
//             image.onload = resolve;
//             image.onerror = reject;
//         });

//         // Step 5: Maintain original aspect ratio of the image
//         const maxImageWidth = canvas.width * 0.2;  // 20% of canvas width
//         const maxImageHeight = canvas.height * 0.2;  // 20% of canvas height
//         let imageWidth = image.width;
//         let imageHeight = image.height;

//         // Scale the image proportionally to fit within the defined max width and height
//         if (imageWidth > maxImageWidth) {
//             const scalingFactor = maxImageWidth / imageWidth;
//             imageWidth = maxImageWidth;
//             imageHeight = imageHeight * scalingFactor;
//         }

//         if (imageHeight > maxImageHeight) {
//             const scalingFactor = maxImageHeight / imageHeight;
//             imageHeight = maxImageHeight;
//             imageWidth = imageWidth * scalingFactor;
//         }

//         // Place the image 20px from the top and 20px from the left
//         const imageX = 20;  // 20px from the left
//         const imageY = 20;  // 20px from the top

//         // Step 6: Set up an animation loop to draw the webcam video and the image onto the canvas
//         const updateCanvas = () => {
//             ctx.clearRect(0, 0, canvas.width, canvas.height);  // Clear the canvas

//             // Draw the webcam video onto the canvas (using videoElement)
//             ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

//             // Draw the image in the upper-left corner, preserving its original aspect ratio
//             ctx.drawImage(image, imageX, imageY, imageWidth, imageHeight);

//             // Continue the animation loop
//             requestAnimationFrame(updateCanvas);
//         };

//         updateCanvas();  // Start the loop

//         // Step 7: Capture the canvas stream (which now contains the webcam video + image)
//         const imageStream = canvas.captureStream(30);  // Capture at 30fps

//         // console.log('Combined stream with image overlay in the upper-left corner created.');

//         // Step 8: Return the combined video stream
//         return imageStream;

//     } catch (error) {
//         console.error('Error while combining image and video stream:', error);
//         throw error;
//     }
// }




//     const handleOnComplete = async (fullConversationText) => {
//         setIsListening(false);
//         setIsSpeaking(false);
//         setIsWaiting(true);
//         // console.log("Usercompany", userCompany);
//         // Stop recording
//         if (recorderRef.current) {

//             if (recorderRef.current.getState() === 'recording') {
//                 recorderRef.current.stopRecording(async () => {
//                     const blob = recorderRef.current.getBlob();

//                     const uniqueId = uuidv4();  // Generate a unique identifier
//                     const filename = `videos/question-${interviewId}-${candidateId}-${CurrentQuestionNumber}-${uniqueId}.mp4`;
//                     // const filename = `question-${interviewId}-${candidateId}-${CurrentQuestionNumber}-${uniqueId}.webm`;
//                     const azurefilename = `videos/question-${interviewId}-${candidateId}-${CurrentQuestionNumber}-${uniqueId}.webm`;
     
//                     // Save the initial response data and get the responsedataid
//                     const initialResponseData = {
//                         "questionnumber": CurrentQuestionNumber,
//                         "candidate": candidateId,
//                         "interview": interviewId,
//                         "questiontext": currentquestionstr.current,
//                         "response_text": fullConversationText,
//                         "response_time": responseStartTm.current,
//                         "questionstarttime": questionStartTm.current,
//                         "responseendtime": responseStartTm.current, // the variable is just passed for placeholder. actual responded time is filled at server side
//                         "usercompany":userCompany
//                     };
    
//                     let responsedataid;
//                     try {
//                         const response = await axios.post(`${config.apiBaseUrl}/api/record_response/`, initialResponseData);
//                         responsedataid = response.data.response_id;
//                         //  console.log("Initial response saved successfully with ID:", responsedataid);
//                     } catch (error) {
//                         console.error("Error saving initial response:", error);
//                         return;
//                     }

//                     const isCloudflare = true; // Set this flag as needed (True for Cloudflare, False for Azure)
//                 // Merge the TTS audio stream with the recorded video blob before uploading
//                     if (isCloudflare) {
//                         uploadVideoToCloudflare(blob, responsedataid, filename)
//                             .then(cloudflareUrl => {
//                                 console.log("Upload completed");
//                             })
//                             .catch((error) => {
//                                 console.error("Error uploading video", error);
//                             });
//                     } else {
//                         // Upload to Azure Blob Storage
//                         uploadVideoToAzure(blob, azurefilename, responsedataid)
//                             .then(azureUrl => {
//                                 console.log("Upload completed and Azure URL obtained:");
//                             })
//                             .catch((error) => {
//                                 console.error("Error uploading video to Azure:", error);
//                             });
//                     }
                 
//                     // Start next question immediately without waiting for the upload to complete
//                     setIsWaiting(false);
//                     setCurrentQuestionNumber(CurrentQuestionNumber + 1);
//                 });
//             }
//         }
//     };



//     function downloadBlob(blob, filename) {
//         // Create a URL for the blob
//         const url = URL.createObjectURL(blob);
        
//         // Create an anchor element
//         const a = document.createElement('a');
//         a.href = url;
//         a.download = filename;  // Set the file name
        
//         // Append the anchor to the body
//         document.body.appendChild(a);
        
//         // Programmatically click the anchor to trigger the download
//         a.click();
        
//         // Clean up by removing the anchor and revoking the object URL
//         document.body.removeChild(a);
//         URL.revokeObjectURL(url);
//     }



// // this function gets a safe upload url from backend and then uploads the video to cloudflare    
// async function uploadVideoToCloudflare(blob, responsedataid, filename) {
//     try {
//             // Step 1: Request a short-lived, time-limited upload URL from the backend

//         const response = await axios.post(`${config.apiBaseUrl}/api/get-cloudflare-upload-url/`, {
//             interviewIdurl: interviewIdurl
//         });

    
//             // Step 2: Use the provided URL to upload the video file directly to Cloudflare
//         const uploadURL = response.data.upload_url;
//         const videoid = response.data.video_id;

//         const formData = new FormData();

//         formData.append("file", blob, filename);  // Pass the filename as the third argument
    
//         const uploadResponse = await axios.post(uploadURL, formData, {
//             headers: {
//                 'Content-Type': 'multipart/form-data',
//             }
//         });     
        
//         const updateResponseData = {
//             "responsedataid": responsedataid,
//             "video_url": videoid,
//             "cloudflare" : 1,
//             "cloudflare_filename" : filename
//         };
//         await axios.post(`${config.apiBaseUrl}/api/save_video_filename/`, updateResponseData);

//         // downloadBlob(blob, filename);
//         return videoid;
    
//     } catch (error) {
//         console.error("Error uploading video:", error);
//         throw error;
//     }
// }
    


//     function uploadVideoToAzure(blob, filename, responsedataid) {
//         return axios.get(`${config.apiBaseUrl}/api/get_sas_token/`, { params: { blob_name: filename } })
//         .then(response => {
//                 const sasToken = response.data.sas_token;
//                 const azureUrl = `https://aiinterviewstorage.blob.core.windows.net/aiblobstorage/${filename}?${sasToken}`;
//                 //console.log("sasToken *******", sasToken)

//                 const config1 = {
//                     headers: {
//                         'x-ms-blob-type': 'BlockBlob',
//                         // 'Content-Type': blob.type,
//                         // 'Content-Type':  "video/mp4",
//                         'Content-Type':  "video/webm",

//                     }
//                 };
    
//                 return axios.put(azureUrl, blob, config1)
//                     .then(() => {
//                         // Save the URL in the database after the upload completes
//                         //console.log("azureUrl *******", azureUrl)

//                         const updateResponseData = {
//                             "responsedataid": responsedataid,
//                             "video_url": azureUrl,
//                             "cloudflare" : 0,
//                         };
//                         return axios.post(`${config.apiBaseUrl}/api/save_video_filename/`, updateResponseData)
//                             .then(() => azureUrl)
//                             .catch(error => {
//                                 console.error("Error updating video URL:", error);
//                                 throw error;
//                             });
//                     })
//                     .catch(error => {
//                         console.error("Error during file upload:", error);
//                         throw error;
//                     });
//             })
//             .catch(error => {
//                 console.error("Error getting SAS token:", error);
//                 throw error;
//             });
//     }
    




// // this function inserts parameters into instructions // DO NOT DELETE. WILL NEED in future
// // const formatTemplate = (template, values, wrapper = '') => {
// //     return template.replace(/\${(.*?)}/g, (match, p1) => {
// //         return values[p1] ? `${values[p1]}` : match;
// //     });
// // };


//     const formatTime = (seconds) => {
//         const mins = Math.floor(seconds / 60);
//         const secs = seconds % 60;
//         return 'Time Eapsed: ' && `${mins}:${secs < 10 ? '0' : ''}${secs}`;
//     };


//     const handleEndInterview = async () => {
//         const confirmEnd = window.confirm('Do you want to end the interview?');
//         if (confirmEnd) {

//             try {
//                 const response = await axios.post(`${config.apiBaseUrl}/api/end_interview/${interviewId}/`);
//                 // console.log(response.data.status);
//                 navigate('/thank-you'); // Redirect to thank you page
//             } catch (error) {
//                 console.error("Error ending interview:", error);
//             }
//         }
//     };


//     const callChatAPI = async (userInput) => {

//         // console.log(userInput);
        

//         const responseData = {
//             instruction: userInput   // instruction is the variable chat api will look for. if instruction variable is not present chatapi will give respone 500
//         };


//         try {
//             const response = await axios.post(`${config.apiBaseUrl}/api/chat/`, responseData);
    
//             // console.log('Chat API response:', response.data);
//             return response.data.question;
//         } catch (error) {
//             console.error('Error calling chat API:', error);
//             return null;
//         }
//     };

//     const fetchQuestionInstruction = async (interviewId) => {
//         try {

//             const response = await axios.get(`${config.apiBaseUrl}/api/question_instruction/${interviewId}/`);
//             return response.data;
//         } catch (error) {
//             console.error('Error fetching question instruction:', error);
//             return null;
//         }
//     };
    
  
//     useEffect(() => {

//         let interval;
//         if (interviewStarted && !interviewEnded) {
//             interval = setInterval(() => {
//                 setTimeElapsed(prevTime => {
//                     if (prevTime + 1 >= 5400) { // 5400 seconds = 90 minutes
//                         // Action to take when 90 minutes have elapsed
//                         console.log('90 minutes have elapsed');
//                         navigate('/thank-you'); // Redirect to thank you page
//                     }
//                     return prevTime + 1;
//                 });
//             }, 1000);
//         }
//         return () => clearInterval(interval);
//     }, [interviewStarted, interviewEnded]);

//     return (
//         <div className="interview-screen" style={{ backgroundColor: avatarbackgroundColor }}>
//             <img src={avatarimageOption} alt="Avatar" className="avatar" />
//             <Rnd
//                 default={{
//                     x: 1,
//                     y: 1,
//                     width: 320,
//                     height: 180,
//                 }}
//                 bounds="parent"
//             >
//                 <div className="webcam-container">
//                 </div>
//                 <Webcam
//                     audio={true}
//                     muted={true}
//                     ref={webcamRef}
//                     width="320"
//                     height="180"
//                     videoConstraints={{
//                         width: 640, // Recording size
//                         height: 360 // Recording size
//                     }}
//                     className="webcam"
//                     style={{ position: 'absolute', top: 1, left: 1 }}
//                     onUserMedia={() => console.log('Webcam loaded')}
//                     onUserMediaError={(err) => console.error('Webcam error:', err)}
//                 />
//                 <canvas
//                     ref={canvasRef}
//                     width="640" // Match the canvas size to the recording size
//                     height="360"
//                     style={{ position: 'absolute', top: 1, left: 1, width: '320px', height: '180px' }} // Display size
//                 />

//             </Rnd>



//             <button onClick={handleStartInterview} className="start-interview-button" disabled={interviewStarted}>Start Interview</button>
//             <div className="question-container">
//                 {interviewStarted ? (
//                     <p>{currentquestionstr.current}</p>
//                 ) : (
//                     <p>{currentTextstr.current}</p>
//                 )}
//             </div>
//             {isSpeaking && (
//                 <div className="speaking-indicator">
//                     <img src="/speaking-icon.png" alt="Speaking" className="blinking-icon" />
//                 </div>
//             )}
//             {isListening && (
//                 <div className="listening-indicator">
//                     <img src="/listening-icon.png" alt="Listening" className="blinking-icon" />
//                 </div>
//             )}
//             {isWaiting && (
//                 <div className="waiting-indicator">
//                     <img src="/waiting-icon.png" alt="Waiting" className="blinking-icon" />
//                 </div>
//             )}
//             {!interviewEnded && (
//                 <button
//                     onClick={handleEndInterview}
//                     className="end-interview-button"
//                     disabled={!interviewStarted}  // Disable if interview hasn't started
//                 >
//                     End Interview
//                 </button>
//             )}
//             <select
//                 id="background-select"
//                 value={backgroundOption}
//                 className="background-select" disabled={interviewStarted}
//                 onChange={(e) => setBackgroundOption(e.target.value)}
//             >
//                 <option value="none">No Camera Background</option>
//                 <option value="blurred">Blurred</option>
//                 <option value="office1">Office 1</option>
//                 <option value="office2">Office 2</option>
//                 <option value="beach">Beach</option>
//             </select>

//             <div style={{
//                 position: 'absolute',
//                 bottom: '275px',
//                 left: '30px',
//                 fontSize: '16px',
//                 color: 'white',
//             }}>
//                 By clicking 'Start Interview' button, you agree to our
//                 <a href="https://www.bitsoft.com/privacy-policy-and-candidate-agreement/" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'underline', marginLeft: '5px' }}>
//                     Terms and Conditions
//                 </a>
//             </div>

//             <div style={{
//                 position: 'absolute',
//                 top: '10px',
//                 right: '10px',
//                 fontSize: '18px',
//                 color: 'white',
//                 backgroundColor: 'rgba(0, 0, 0, 0.5)', // optional: adds a semi-transparent background for better readability
//                 padding: '5px', // optional: adds padding around the text
//                 borderRadius: '5px' // optional: adds rounded corners
//             }}>
//                 Time elapsed: {formatTime(timeElapsed)}
//             </div>
//             <div style={{
//                 position: 'absolute',
//                 bottom: '5px',
//                 right: '10px',
//                 fontSize: '12px',
//                 color: 'white',
//                 padding: '5px',
//             }}>
//                 <a href="http://www.bitsoft.com" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none' }}>
//                     Bitsoft International, Inc © 2024
//                 </a>
//             </div>
//         </div>
//     );
// };

// export default InterviewScreen;


//*********************************************************************************************************************************** */

// return (
//     <div className="interview-screen" style={{ backgroundColor: avatarbackgroundColor }}>
//     <img src={avatarimageOption} alt="Avatar" className="avatar" />
    // <Rnd
    //     default={{
    //         x: 1,
    //         y: 1,
    //         width: 320,
    //         height: 180,
    //     }}
    //     bounds="parent"
    // >
    //     <div className="webcam-container">
    //     </div>
    //         <Webcam
    //             audio={true}
    //             muted={true}
    //             ref={webcamRef}
    //             width="320"
    //             height="180"
    //             videoConstraints={{
    //                 width: 640, // Recording size
    //                 height: 360 // Recording size
    //             }}
    //             className="webcam"
    //             style={{ position: 'absolute', top: 1, left: 1 }}
    //             onUserMedia={() => console.log('Webcam loaded')}
    //             onUserMediaError={(err) => console.error('Webcam error:', err)}
    //         />
    //         <canvas
    //             ref={canvasRef}
    //             width="640" // Match the canvas size to the recording size
    //             height="360"
    //             style={{ position: 'absolute', top: 1, left: 1, width: '320px', height: '180px' }} // Display size
    //         />
        
    // </Rnd>
//         <button onClick={handleStartInterview} className="start-interview-button" disabled={interviewStarted}>Start Interview</button>
//         <div className="question-container">
//         {interviewStarted ? (
//         <p>{currentquestionstr.current}</p>
//     ) : (
//         <p>{currentTextstr.current}</p>
//     )}
//         </div>
//         {isSpeaking && (
//             <div className="speaking-indicator">
//                 <img src="/speaking-icon.png" alt="Speaking" className="blinking-icon" />
//             </div>
//         )}
//         {isListening && (
//             <div className="listening-indicator">
//                 <img src="/listening-icon.png" alt="Listening" className="blinking-icon" />
//             </div>
//         )}
//         {isWaiting && (
//             <div className="waiting-indicator">
//                 <img src="/waiting-icon.png" alt="Waiting" className="blinking-icon" />
//             </div>
//         )}
//         {!interviewEnded && (
//             <button 
//                 onClick={handleEndInterview} 
//                 className="end-interview-button" 
//                 disabled={!interviewStarted}  // Disable if interview hasn't started
//             >
//                 End Interview
//             </button>
//         )}
//                     <select
//                         id="background-select"
//                         value={backgroundOption}
//                         className="background-select" disabled={interviewStarted}
//                         onChange={(e) => setBackgroundOption(e.target.value)}
//                     >
//                         <option value="none">No Camera Background</option>
//                         <option value="blurred">Blurred</option>
//                         <option value="office1">Office 1</option>
//                         <option value="office2">Office 2</option>
//                         <option value="beach">Beach</option>
//                     </select>
    
//                     <div style={{ 
//                     position: 'absolute', 
//                     bottom: '275px', 
//                     left: '30px', 
//                     fontSize: '16px', 
//                     color: 'white',
//                     }}>
//         By clicking 'Start Interview' button, you agree to our 
//         <a href="https://www.bitsoft.com/privacy-policy-and-candidate-agreement/" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'underline', marginLeft: '5px' }}>
//             Terms and Conditions
//         </a>
//     </div>
    
//         <div style={{ 
//             position: 'absolute', 
//             top: '10px', 
//             right: '10px', 
//             fontSize: '18px', 
//             color: 'white',
//             backgroundColor: 'rgba(0, 0, 0, 0.5)', // optional: adds a semi-transparent background for better readability
//             padding: '5px', // optional: adds padding around the text
//             borderRadius: '5px' // optional: adds rounded corners
//         }}>
//             Time elapsed: {formatTime(timeElapsed)}
//         </div>
//         <div style={{ 
//             position: 'absolute', 
//             bottom: '5px', 
//             right: '10px', 
//             fontSize: '12px', 
//             color: 'white',
//             padding: '5px',
//         }}>
//         <a href="http://www.bitsoft.com" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none' }}>
//             Bitsoft International, Inc © 2024
//         </a>
//     </div>
//     </div>
//         );
//     };
    
//     export default InterviewScreen;
    