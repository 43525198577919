// // good code below this
import * as SpeechSDK from 'microsoft-cognitiveservices-speech-sdk';

const subscriptionKey = '49cfae0357764d2b86ff759b7f2eeeb9'; // this is for AIInterview2
const serviceRegion = 'centralus';
const speechConfig = SpeechSDK.SpeechConfig.fromSubscription(subscriptionKey, serviceRegion);
// speechConfig.speechSynthesisVoiceName = "en-US-AndrewMultilingualNeural"; // Professional human voice

export const initializeSpeechSynthesizer = (voicename) => {
    try {
        // Set the voice name dynamically
        speechConfig.speechSynthesisVoiceName = voicename || "en-US-AndrewMultilingualNeural"; // Default voice if none passed

        const player = new SpeechSDK.SpeakerAudioDestination();
        const audioConfig = SpeechSDK.AudioConfig.fromSpeakerOutput(player);
        const synthesizer = new SpeechSDK.SpeechSynthesizer(speechConfig, audioConfig);
        return { synthesizer, player };
    } catch (error) {
        console.error('Failed to initialize speech synthesizer:', error);
        return null;
    }
};

// Detect Safari browser
const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

const attemptSynthesis = (synthesizer, text, player, onComplete, retryCount = 0) => {
    synthesizer.speakTextAsync(
        text,
        result => {
            if (result.reason === SpeechSDK.ResultReason.SynthesizingAudioCompleted) {
                player.onAudioEnd = () => {
                    if (onComplete) onComplete();
                };
            } else {
                console.error('Speech synthesis error:', result.errorDetails);
                if (onComplete) onComplete();
            }
            synthesizer.close();
        },
        err => {
            console.error('Speech synthesis failure:', err);
            if (retryCount < 5 && err.message.includes("Websocket not ready")) {
                console.log(`Retrying synthesis... Attempt ${retryCount + 1}`);
                setTimeout(() => attemptSynthesis(synthesizer, text, player, onComplete, retryCount + 1), 1000);
            } else {
                if (onComplete) onComplete();
                synthesizer.close();
            }
        }
    );
};

export const speakText = (synthesizerObj, text, onComplete, setTTSBlob) => {
    if (!synthesizerObj) {
        console.error('Invalid synthesizer object.');
        if (onComplete) onComplete();
        return;
    }

    const { synthesizer, player } = synthesizerObj;

    try {
        synthesizer.speakTextAsync(text, (result) => {
            if (result.reason === SpeechSDK.ResultReason.SynthesizingAudioCompleted) {
                // Convert the audioData into a Blob
                const ttsAudioBlob = isSafari
                    ? new Blob([result.audioData], { type: "audio/mp3" }) // Safari-compatible format
                    : new Blob([result.audioData], { type: "WebM" }); // Default format

                // Always execute setTTSBlob regardless of browser
                setTTSBlob(ttsAudioBlob);

                // Wait for the audio to finish playing
                if (isSafari) {
                    const audioUrl = URL.createObjectURL(ttsAudioBlob);
                    const audio = new Audio(audioUrl);
                
                    audio.onended = () => {
                        URL.revokeObjectURL(audioUrl); // Release memory
                        if (onComplete) onComplete();
                    };
                
                    // Ensure audio playback starts after user interaction
                    const playPromise = audio.play();
                    if (playPromise !== undefined) {
                        playPromise.catch((error) => {
                            console.error('Safari playback error:', error);
                        });
                    }
                } else {
                    player.onAudioEnd = () => {
                        if (onComplete) onComplete();
                    };
                }
            } else {
                console.error('Speech synthesis error:', result.errorDetails);
                if (onComplete) onComplete();
            }
            synthesizer.close(); // Close the synthesizer
        }, (err) => {
            console.error('Speech synthesis failure:', err);
            if (onComplete) onComplete();
            synthesizer.close();
        });
    } catch (error) {
        console.error('Error during speech synthesis:', error);
        if (onComplete) onComplete();
    }
};

export const stopSpeech = (synthesizerObj) => {
    try {
        if (synthesizerObj && synthesizerObj.synthesizer) {
            synthesizerObj.synthesizer.close();
        }
    } catch (error) {
        console.error('Error stopping speech synthesis:', error);
    }
};



// // // good code below this
// import * as SpeechSDK from 'microsoft-cognitiveservices-speech-sdk';

// const subscriptionKey = '49cfae0357764d2b86ff759b7f2eeeb9'; // this is for AIInterview2
// const serviceRegion = 'centralus';
// const speechConfig = SpeechSDK.SpeechConfig.fromSubscription(subscriptionKey, serviceRegion);
// // speechConfig.speechSynthesisVoiceName = "en-US-AndrewMultilingualNeural"; // Professional human voice

// export const initializeSpeechSynthesizer = (voicename) => {
//     try {
//         // Set the voice name dynamically
//         speechConfig.speechSynthesisVoiceName = voicename || "en-US-AndrewMultilingualNeural"; // Default voice if none passed

//         const player = new SpeechSDK.SpeakerAudioDestination();
//         const audioConfig = SpeechSDK.AudioConfig.fromSpeakerOutput(player);
//         const synthesizer = new SpeechSDK.SpeechSynthesizer(speechConfig, audioConfig);
//         return { synthesizer, player };
//     } catch (error) {
//         console.error('Failed to initialize speech synthesizer:', error);
//         return null;
//     }
// };


// const attemptSynthesis = (synthesizer, text, player, onComplete, retryCount = 0) => {
//     synthesizer.speakTextAsync(
//         text,
//         result => {
//             if (result.reason === SpeechSDK.ResultReason.SynthesizingAudioCompleted) {

//                   player.onAudioEnd = () => {
//                     if (onComplete) onComplete();
//                 };
//             } else {
//                 console.error('Speech synthesis error:', result.errorDetails);
//                 if (onComplete) onComplete();
//             }
//             synthesizer.close();
//         },
//         err => {
//             console.error('Speech synthesis failure:', err);
//             if (retryCount < 5 && err.message.includes("Websocket not ready")) {
//                 console.log(`Retrying synthesis... Attempt ${retryCount + 1}`);
//                 setTimeout(() => attemptSynthesis(synthesizer, text, player, onComplete, retryCount + 1), 1000);
//             } else {
//                 if (onComplete) onComplete();
//                 synthesizer.close();
//             }
//         }
//     );
// };


// export const speakText = (synthesizerObj, text, onComplete, setTTSBlob) => {
//     if (!synthesizerObj) {
//         console.error('Invalid synthesizer object.');
//         if (onComplete) onComplete();
//         return;
//     }

    
//     const { synthesizer, player } = synthesizerObj;

    
//     try {
//         synthesizer.speakTextAsync(text, (result) => {
//             if (result.reason === SpeechSDK.ResultReason.SynthesizingAudioCompleted) {

//                 // Convert the audioData into a Blob and pass it back using the setTTSBlob function
//                 const ttsAudioBlob = new Blob([result.audioData], { type: "WebM" });

//                 // Check if setTTSBlob is passed correctly
//                 if (setTTSBlob) {
//                     setTTSBlob(ttsAudioBlob);  // Ensure audioContext and destination are passed
//                 } else {
//                     console.error('setTTSBlob is not defined');
//                 }

//                 // Wait for the audio to finish playing
//                 player.onAudioEnd = () => {
//                     if (onComplete) onComplete();
//                 };
//             } else {
//                 console.error('Speech synthesis error:', result.errorDetails);
//                 if (onComplete) onComplete();
//             }
//             synthesizer.close(); // Close the synthesizer
//         }, (err) => {
//             console.error('Speech synthesis failure:', err);
//             if (onComplete) onComplete();
//             synthesizer.close();
//         });
//     } catch (error) {
//         console.error('Error during speech synthesis:', error);
//         if (onComplete) onComplete();
//     }
// };

// export const stopSpeech = (synthesizerObj) => {
//     try {
//         if (synthesizerObj && synthesizerObj.synthesizer) {
//             synthesizerObj.synthesizer.close();
//         }
//     } catch (error) {
//         console.error('Error stopping speech synthesis:', error);
//     }
// };
