import React from 'react';
import './ThankYouScreen.css';

const InvalidLink = () => {
    return (
        <div className="thank-you-screen">
            <div className="thank-you-message">
                <h1>Invalid link or interview has already taken place. Please contact support or your recruiter</h1>
            </div>
        </div>
    );
};

export default InvalidLink;