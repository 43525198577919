import React from 'react';
import './ThankYouScreen.css';

const ThankYouScreen = () => {
    return (
        <div className="thank-you-screen">
            <div className="thank-you-message">
                <h1>Thanks for taking the interview with us. This interview is now closed.</h1>
            </div>
        </div>
    );
};

export default ThankYouScreen;