import * as SpeechSDK from 'microsoft-cognitiveservices-speech-sdk';

// Define constants for better readability
const subscriptionKey = '49cfae0357764d2b86ff759b7f2eeeb9'; // this is for AIInterview2
const serviceRegion = 'centralus';

const speechConfig = SpeechSDK.SpeechConfig.fromSubscription(subscriptionKey, serviceRegion);
speechConfig.setProperty(SpeechSDK.PropertyId.SpeechServiceConnection_InitialSilenceTimeoutMs, "15000");
speechConfig.setProperty(SpeechSDK.PropertyId.SpeechServiceConnection_EndSilenceTimeoutMs, "15000");

// Recognizer state enumeration for clarity
const RecognizerState = {
    INITIALIZED: 'INITIALIZED',
    RECOGNIZING: 'RECOGNIZING',
    STOPPED: 'STOPPED',
    ERROR: 'ERROR'
};

// Initialize recognizer with proper state management
export const initializeSpeechRecognizer = () => {
    try {
        const audioConfig = SpeechSDK.AudioConfig.fromDefaultMicrophoneInput();
        const recognizer = new SpeechSDK.SpeechRecognizer(speechConfig, audioConfig);
        recognizer.conversationText = '';
        recognizer.state = RecognizerState.INITIALIZED; // Explicit state tracking
        return recognizer;
    } catch (error) {
        console.error('Failed to initialize speech recognizer:', error);
        return null;
    }
};

// Exponential backoff retry mechanism with IIFE to handle closures
const retryOperation = async (operation, retries, delay) => {
    for (let attempt = 1; attempt <= retries; attempt++) {
        const currentDelay = delay;
        try {
            await operation();
            return; // Exit if operation is successful
        } catch (error) {
            console.error(`Attempt ${attempt} failed:`, error);
            if (attempt < retries) {
                await new Promise(resolve => setTimeout(resolve, currentDelay));
                delay *= 2; // Exponential backoff
            } else {
                console.error('All retry attempts failed.');
                throw error; // Throw error if all retries fail
            }
        }
    }
};

// Start recognition with robust handling
export const startContinuousRecognition = (recognizer, onComplete, retries = 5, delay = 1000) => {
    if (!recognizer || recognizer.state !== RecognizerState.INITIALIZED) {
        console.warn("Recognizer is not initialized or already recognizing.");
        return;
    }

    recognizer.state = RecognizerState.RECOGNIZING;
    let silenceTimeout;
    const silenceDuration = 8000;

    const resetSilenceTimeout = () => {
        if (silenceTimeout) {
            clearTimeout(silenceTimeout);
        }
        silenceTimeout = setTimeout(() => {
            // Check if the recognizer is still recognizing before stopping
            if (recognizer.state === RecognizerState.RECOGNIZING) {
                stopContinuousRecognition(recognizer, onComplete);
            }
        }, silenceDuration);
    };

    recognizer.recognizing = (s, e) => {
        resetSilenceTimeout();
    };

    recognizer.recognized = (s, e) => {
        if (e.result.reason === SpeechSDK.ResultReason.RecognizedSpeech) {
            recognizer.conversationText += ` ${e.result.text}`;
            resetSilenceTimeout();
        } else if (e.result.reason === SpeechSDK.ResultReason.NoMatch) {
            recognizer.conversationText += "";
        }
    };

    recognizer.canceled = (s, e) => {
        console.error(`Recognition canceled. Reason: ${e.reason}, ErrorDetails: ${e.errorDetails}`);
        recognizer.state = RecognizerState.STOPPED;
        stopContinuousRecognition(recognizer, onComplete);
    };

    recognizer.sessionStopped = (s, e) => {
        // console.log("Recognition session stopped.");
        recognizer.state = RecognizerState.STOPPED;
    };

    const startRecognition = () => {
        return new Promise((resolve, reject) => {
            recognizer.startContinuousRecognitionAsync(
                () => {
                    // console.log("Continuous recognition started.");
                    resetSilenceTimeout();
                    resolve();
                },
                err => {
                    console.error("Failed to start continuous recognition:", err);
                    recognizer.state = RecognizerState.ERROR;
                    reject(err);
                }
            );
        });
    };

    retryOperation(startRecognition, retries, delay)
        .catch(error => {
            recognizer.state = RecognizerState.ERROR;
            console.error("Recognition start operation failed after retries:", error);
        });
};

// Stop recognition with robust handling
export const stopContinuousRecognition = (recognizer, onComplete, retries = 5, delay = 1000) => {
    if (!recognizer) {
        console.warn("Recognizer is not initialized.");
        if (onComplete) onComplete('');
        return;
    }

    if (recognizer.state !== RecognizerState.RECOGNIZING) {
        console.warn("Recognizer is not recognizing or has already stopped.");
        if (onComplete) onComplete(recognizer.conversationText);
        return;
    }

    const stopRecognition = () => {
        return new Promise((resolve, reject) => {
            recognizer.stopContinuousRecognitionAsync(
                () => {
                    // console.log("Continuous recognition stopped.");
                    recognizer.state = RecognizerState.STOPPED;
                    if (onComplete) {
                        onComplete(recognizer.conversationText);
                    }
                    resolve();
                },
                err => {
                    console.error("Failed to stop continuous recognition:", err);
                    recognizer.state = RecognizerState.ERROR;
                    reject(err);
                }
            );
        });
    };

    retryOperation(stopRecognition, retries, delay)
        .catch(error => {
            recognizer.state = RecognizerState.ERROR;
            console.error("Recognition stop operation failed after retries:", error);
            if (onComplete) {
                onComplete(recognizer.conversationText);
            }
        });
};

